@import '../../../../assets/variables';

.modal--order-notes {
  border-radius: 4px;
  padding: 30px 50px 25px 50px;
  width: fit-content;
  transform: translate(-50%, -55%);

  .notes-icon {
    font-size: 20px;
  }

  .title {
    font-size: 25px;
    display: inline;
    margin-right: 10px;
  }

  .text {
    font-size: 13px;
    margin: 3px 0;

    &--note-type {
      font-size: 15px;
      margin: 15px 0 10px 0;
      font-style: italic;
      font-family: $font-family-bold;
    }
  }
  .modal--content-centered {
    text-align: center;
  }
}

.notes-list {
  margin-bottom: 20px;
  list-style-type: disc !important;
}
